.background-img {
  background-image: url("./background.png");
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
}

.logo-img {
  background-image: url("./single-tones.png");
  /* min-width: 100%;
  min-height: 100%; */
  background-repeat: no-repeat;
}

.Qrback {
  z-index: -1;
}
.Qr-wrapper {
  top: 72px;
}
.backQr {
  height: 451px;
  width: 466px;
}

.Qr-white-back {
  height: 348px;
  background: url("./QrBack.png") 100% 100% no-repeat;
  background-position: center;
  background-size: cover;
}

.Qr-white-backLg {
  height: 569px;
  background: url("./QrBack.png") 100% 100% no-repeat;
  background-position: center;
  background-size: contain;
}

.qr-setting {
  padding-top: 86px;
}

.landscape-view {
  display: none;
}

/* @media only screen and (max-width: 500px) {
  .Qr-wrapper {
    top: 32px;
  }
}
@media only screen and (max-width: 550px) and (min-width: 400px) {
  .Qr-wrapper {
    top: 76px;
  }
} */

@media (orientation: landscape) {
  .landscape-view {
    display: block;
  }
  .Qr-white-backLg {
    height: 407px;
  }
  .qr-setting {
    padding-top: 55px;
  }
}
