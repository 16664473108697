.sign-in-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 8em;
  overflow: hidden;
  gap: 3.6em;
}

.sign-in-background {
  position: relative;
  overflow: hidden;
  background: white;
}

.sign-forms {
  display: flex;
  flex-direction: column;

  align-content: center;
}

.signform {
  border-radius: 30px;
  background: #f3f3f3;
  padding: 12px;
  font-size: 22px;
  width: 368px;
  font-family: "Arial, Helvetica, sans-serif;  " !important;
}
.sign-in-wrapper img {
  height: 70px;
}

.sign-inbtn {
  padding: 12px 70px;
  border-radius: 30px;
  text-align: center;
  border: none;
  align-self: center;
  font-size: 19px;
  text-decoration: none;
  color: white;
  /* margin-left: 10%; */
  background: #2cb9cf;
}

.sign-inbtn:hover {
  color: white;
}
.sign-in-wrapper a {
  text-decoration: none;
  font-family: "Arial, Helvetica, sans-serif;" !important;
}

/* .error-msg {
    position: absolute;
    left: 320px;
    top: 20px;
    white-space: nowrap;
  } */

.error-hndl {
  position: relative;
}
/*  */
.logout {
  display: block;

  margin-top: 30px;
  margin-left: 410px;
}
.logout1 {
  display: block;

  margin-top: 30px;
  margin-left: 10px;
  /* margin-right: 10%; */
}
@media only screen and (max-width: 500px) {
  .signform {
    font-size: 15px;
    width: 309px;
  }
  .sign-inbtn {
    font-size: 11px;
  }
}
